import SettingsIcon from '@material-ui/icons/Settings';
import {
    ContainerFieldsList,
    CustomBreadcrumbs,
    CustomSelectArrayInput,
    CustomSelectInput,
} from 'candy-commons-components';
import React, {
    useState,
    useRef,
    useEffect,
    useCallback,
    useMemo,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
    ArrayInput,
    BooleanInput,
    Edit,
    ImageField,
    NumberInput,
    SimpleForm,
    SimpleFormIterator,
    SimpleShowLayout,
    TextInput,
    required,
    useTranslate,
    FormDataConsumer,
} from 'react-admin';
import CustomToolbar from '../../components/CustomToolbar';
import ImageInput from '../../components/ImageInput';
import applianceType from '../../data/applianceType.json';
import brand from '../../data/brand.json';
import display from '../../data/display.json';
import series from '../../data/series.json';
import loadingType from '../../data/loadingType.json';
import type_ from '../../data/type.json';
import options from './applianceWakeUp.mock';
import EnrollmentSection from '../../components/enrollmentSection';
import useEnrollmentForm from '../../hooks/useEnrollmentForm';
import DRYING_TECHNOLOGY from '../../data/dryingTechnology.json';

const useStyles = makeStyles({
    width: {
        width: '100% !important',
    },
});

const ApplianceWakeUpEdit = ({ permissions = {}, ...props }) => {
    const translation = useTranslate();
    const [tdChoices, setTdChoices] = useState(false);
    const classes = useStyles();

    const {
        enrollmentType,
        setEnrollmentType,
        resetGestureDisabled,
        easyEnrollmentDisabled,
        standardEnrollmentDisabled,
        singlePairingDisabled,
        handleEnrollmentTypeChange,
        onInputChange,
    } = useEnrollmentForm(true);
    const [initialEnrollmentType, setInitialEnrollmentType] = useState(null);
    const isEditDisabled = !permissions['appliance-wake-up']?.update;
    const { id } = props;
    const breadcrumbsData = [
        {
            text: translation('resources.appliance-wake-up.name'),
            url: 'appliance-wake-up',
            icon: <SettingsIcon />,
        },
        {
            text: id,
            url: '',
        },
    ];

    const handleApplianceTypeChange = useCallback((event) => {
        setTdChoices(event.target.value === 'TD');
    }, []);

    const enrollmentSection = useMemo(
        () =>
            ({ formData, ...rest }) => {
                if (enrollmentType === null) {
                    setEnrollmentType(formData.options);
                }
                return (
                    <EnrollmentSection>
                        <BooleanInput
                            initialValue={false}
                            source="resetGesture"
                            label="Reset gesture"
                            disabled={resetGestureDisabled || isEditDisabled}
                        />
                        <BooleanInput
                            initialValue={false}
                            source="easyEnrollment"
                            label="Easy enrollment"
                            disabled={easyEnrollmentDisabled || isEditDisabled}
                            onChange={(val) =>
                                onInputChange('easyEnrollment', val)
                            }
                        />
                        <BooleanInput
                            initialValue={false}
                            source="standardEnrollment"
                            label="Standard enrollment"
                            disabled={
                                standardEnrollmentDisabled || isEditDisabled
                            }
                            onChange={(val) =>
                                onInputChange('standardEnrollment', val)
                            }
                        />
                        <BooleanInput
                            initialValue={false}
                            disabled={singlePairingDisabled || isEditDisabled}
                            source="singlePairing"
                            label="Single pairing"
                            onChange={(val) =>
                                onInputChange('singlePairing', val)
                            }
                        />
                    </EnrollmentSection>
                );
            },
        [
            resetGestureDisabled,
            easyEnrollmentDisabled,
            standardEnrollmentDisabled,
            isEditDisabled,
        ]
    );
    return (
        <>
            <CustomBreadcrumbs data={breadcrumbsData} />
            <Edit {...props}>
                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            permissions={permissions}
                            isEditDisabled={isEditDisabled}
                        />
                    }
                >
                    <ContainerFieldsList>
                        <CustomSelectInput
                            source="applianceType"
                            choices={applianceType}
                            validate={required()}
                            onChange={handleApplianceTypeChange}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="brand"
                            choices={brand}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="series"
                            choices={series}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="seriesVersion"
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="loadingType"
                            choices={loadingType}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="installationType"
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            label="Drying Technology"
                            source="dryingTechnology"
                            disabled={isEditDisabled}
                            choices={DRYING_TECHNOLOGY}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="type"
                            choices={type_}
                            disabled={isEditDisabled}
                        />
                        <CustomSelectArrayInput
                            allowEmpty
                            source="display"
                            choices={display}
                            disabled={isEditDisabled}
                        />
                        <TextInput
                            source="title"
                            label="title"
                            disabled={isEditDisabled}
                            validate={required()}
                        />
                        <TextInput
                            source="description"
                            label="description"
                            disabled={isEditDisabled}
                        />
                        <CustomSelectInput
                            allowEmpty
                            source="options"
                            label="Enrollment type"
                            choices={options}
                            disabled={isEditDisabled}
                            translateChoice={false}
                            onChange={handleEnrollmentTypeChange}
                        />
                    </ContainerFieldsList>
                    <ContainerFieldsList>
                        <FormDataConsumer>{enrollmentSection}</FormDataConsumer>
                    </ContainerFieldsList>
                    <hr className={classes.width} />
                    <ArrayInput source="steps" disabled={isEditDisabled}>
                        <SimpleFormIterator
                            getItemLabel={(index) => `${index + 1}-key`}
                        >
                            <TextInput
                                source="stepTitle"
                                label="Step title"
                                validate={required()}
                            />
                            <TextInput
                                source="stepSubtitle"
                                label="Step subtitle"
                            />
                            <SimpleShowLayout>
                                <ImageField source="imagePath" />
                            </SimpleShowLayout>
                            {/* <TextInput label="title" source="title" /> */}
                            <TextInput
                                label="description"
                                source="description"
                            />
                            <BooleanInput
                                initialValue={false}
                                source="standard"
                                label="Standard flow (wifi)"
                            />
                            <NumberInput source="delay" label="Delay" />
                            {!isEditDisabled && (
                                <ImageInput
                                    source="imagePath"
                                    label="Image"
                                    accept="image/*"
                                    placeholder={<p>Drop your file here</p>}
                                >
                                    <ImageField source="src" title="title" />
                                </ImageInput>
                            )}
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Edit>
        </>
    );
};
export default ApplianceWakeUpEdit;
